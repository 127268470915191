.checkMailForm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin: auto 0;
  flex-direction: column;
  width: fit-content;
  background: white;
  padding: 5rem;
  border-radius: 8px;
}

.checkMailSection {
  margin-bottom: 30px;
}

@primary-color: #FFA39E;