@import "~antd/lib/style/themes/default.less";

.profilePicture {
  height: 60px;
  width: auto;
}

.profilePictureContainer {
  height: 80px;
  width: 80px;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #aaaaaa;
}

.noPicture {
  .profilePictureContainer();
  color: #aaaaaa;
  font-size: 12px;
  border: 1px solid #aaaaaa;
  cursor: pointer;
}

.pictureContainer {
  align-items: center;
  display: flex;
  padding-left: 1rem;
}

.picturePreview {
  max-height: 500px;
  max-width: 500px;
  width: auto;
  border-radius: 8px;
}

.uploadModal {
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.style {
  min-width: 900px;
}

@primary-color: #FFA39E;