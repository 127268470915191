@import "~antd/lib/style/themes/default.less";

@headerFooterHeight: 100px;
@borderGrey: #f4dacf;

.downOutlined {
  padding-left: 15px;
  padding-top: 3px;
  font-size: 10px;
}

.closeOutlined {
  font-size: 18px;
  color: #ff0000;
}

.checkOutlined {
  font-size: 18px;
  color: #00dbc8;
}

.fontSizeWeight {
  font-size: 16px;
  font-weight: 600;
}

.buttonActive {
  .fullCentered();
  background: #ffa39e;
  height: 30px;
  padding: 0 10px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
}

.buttonPending {
  .buttonActive();
  background: #ffc089;
}

.buttonDone {
  .buttonActive();
  background: #00dbc8;
}

.buttonOnGoing {
  .buttonActive();
  background: #84abff;
}

.buttonWidth {
  .fullCentered();
  width: 130px;
  height: 40px;
  font-weight: 900;
  font-size: 15px;
  flex-direction: row;
  padding: 10px 20px;
}

.passwordButton {
  .buttonWidth();
  align-items: center;
  width: 100%;
  border-radius: 12px;
  margin-top: 35px;
  height: 45px;
}

.buttonPath {
  .buttonWidth();
  margin-left: 30px;
}

.buttonAddFriend {
  .buttonWidth();
  height: 45px;
  width: 300px;
  margin-top: 5px;
}

.fullCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fullRight {
  .fontSizeWeight();
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 65px;
}

.horizontalRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.horizontalRowStyle {
  .horizontalRow();
  margin-top: 20px;
}

.nameStyle {
  margin-top: 20px;
}

.title {
  display: flex;
  color: #06122a;
  font-size: 13px;
  font-weight: 700;
}

.titleStyle {
  .title();
  padding-top: 30px;
}

.descriptionStyle {
  .title();
  padding-top: 20px;
}

.dropDownContainer {
  height: 45px;
}

.card {
  width: 100%;
  flex: 1;
  height: fit-content;
  max-height: fit-content;
  .ant-layout-content {
    flex: 0;
  }
}

.page {
  .fullCentered();
  padding: 2rem;
  display: flex;
  flex: 1;
  min-height: 100%;
  min-width: 100%;
  background: #f7f9fc;
}

.pageContainer {
  background: white !important;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  margin: auto;
  border-radius: 8px;
  max-width: 1400px;
  min-width: 900px;
  padding: 2rem;
  & .ant-table-footer {
    padding: 0 !important;
  }
}

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nextPrev {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tableValue {
  font-weight: 500;
  color: #06122a;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.tableHeading {
  .tableValue();
  font-weight: 700;
}

.editPicture {
  font-weight: 800;
  font-size: 13px;
  color: #000000;
  padding-top: 40px;
}

.upload {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.uploadUserPicture {
  .upload();
  margin-top: 20px;
}

.editLeftCol {
  .fullLeft();
  margin-top: 20px;
}
