@import "~antd/lib/style/themes/default.less";

.verticalContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  display: flex;
  color: #06122a;
  font-size: 13px;
  font-weight: 700;
}

.boxContainer {
  border-radius: 12px;
  padding-left: 10px;
  height: 45px;
  border: 1px solid #d9d9d9;
  color: #06122a;
  outline: #ebedef;
  &:disabled {
    color: #a9a9a9 !important;
  }
}

@primary-color: #FFA39E;