.editContainerWidth {
  min-width: 700px;
  .ant-modal-body {
    min-height: unset !important;
  }
}

.editText {
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
}

.modalButton {
  .editText();
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 10px;
}
@primary-color: #FFA39E;