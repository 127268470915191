.editText {
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
}

.deleteModal {
  .modalButton {
    .editText();
    margin: 0;
  }
  
  .ant-modal-body {
    padding: 0;
  }
  
  .ant-modal-header {
    border: 0;
  }
}
@primary-color: #FFA39E;