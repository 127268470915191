.topHeader {
  width: 100%;
  height: fit-content;
  background: transparent;
  margin-bottom: 10px;
}

.titleHeader{
  font-size: 25px;
  font-weight: 600;
}

.titleFilter{
  font-size: 17px;
  font-weight: 600;
  color: #06122a;
}
.funnel {
  color: @primary-color;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
  
.funnelIcon {
  font-size: 18px;
  color: #ffa39e;
}

.noBorder{
  border: none;
  box-shadow: none;
  margin-left: 10px;
  &:after {
    content: none !important;
  }
}
@primary-color: #FFA39E;