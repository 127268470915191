.IdCopyContainer {
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.id {
  font-family: 'Inter' !important;
  font-weight: 500;
  font-style:normal;
  color: #06122a;
  font-size: 12px;
  margin-right: 10px;
}

.copyOutlined{
  color: #aaaaaa;
}

@primary-color: #FFA39E;