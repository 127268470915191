.loginForm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin: auto 0;
  flex-direction: column;
  width: fit-content;
  background: white;
  padding: 5rem;
  border-radius: 8px;
}

.loginSection {
    margin-bottom: 30px;

}

.loginItem {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px !important;
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active
  {
   -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}
  
.loginItemInput {
  width: 400px;
  border-radius: 12px;
  height: 50px;
}

.buttonSubmit {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  width: 400px;
  border-radius: 12px;
  height: 50px;
  margin-top: 15px;
}
@primary-color: #FFA39E;