.actionsContainer {
  width: 100%; 
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  background: white !important;
}

.buttonDelete {
  color: #06122a !important;
  border: none;
  font-weight: 600;
  font-size: 17px;
  align-items: center;
  height: 38px;
  margin-right: 20px;
  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background: white !important;
  }
  &:hover {
    color: #06122a !important;
  }
}
  
.buttonCreate {
  color: #fff;
  background-color: @primary-color;
  border: none;
  font-weight: 600;
  font-size: 17px;
  align-items: center;
  height: 38px;
}
@primary-color: #FFA39E;