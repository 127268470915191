@import "../../UI/CommonStyles.less";

.checkbox {
  .fullLeft();
}

.checkboxTitle {
  .fullRight();
  margin-left: 5px;
  font-size: 12px;
  font-weight: 700;
}

@primary-color: #FFA39E;