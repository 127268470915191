@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mainMenu {
  z-index: 100;
  position: fixed;
  height: 100vh;
  width: 300px;
}

.mainMenuContent{
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #06122A;
  margin: 0px !important;
  padding-left: 0px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  padding: 15px 40px !important;
  .ant-menu-item-icon, .anticon-logout {
    margin-left: 0px !important;
    margin-right: 16px !important;
  }
  .anticon svg{
    font-size: 18px !important;
  }
}

.sider {
  width: 300px;
  height: 100vh;
}

.layoutStyle{
  height: 100vh;
}

.logout{
  .mainMenuContent();
  border: none;
  box-shadow: none;
  position: absolute;
  right: 0;
  bottom: 30px;
}

.appHeader {
  background-color: #FFA39E;;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  position: 'sticky'; 
  top: 0;
  left: 0; 
  z-index: 1; 
  width: '100%';
}

.eveiaAdmin{
  color: "white";
  position: "relative";
  top: 8;
}

.headerMenu {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
}

.logoMenuContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.logoMenu {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.traduction{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
}

.buttonContact {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: @primary-color;
  font-weight: 400;
  font-size: 16px;
  bottom: 100px;
  flex-direction: column;
  padding: 10px 20px;
  position: absolute;
  width: 207px;
  height: 41px;
  left: 40px;
}

.ant-modal-title{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #06122A;
}

.ant-menu-item-icon {
  font-size: 18px !important;
}

.ant-picker.ant-picker-disabled{
  background: #efefef4d ;
  border-color: #d9d9d9 ;
}
@primary-color: #FFA39E;