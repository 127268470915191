.ResetPasswordForm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin: auto 0;
  flex-direction: column;
  width: fit-content;
  background: white;
  padding: 5rem;
  border-radius: 8px;
}

.ResetPasswordSection {
  margin-bottom: 30px;
}

.inputresetPassword {
  all: unset;
  font-size: 16px;
  min-width: 350px;
}

.buttonSubmit {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  width: 400px;
  border-radius: 12px;
  height: 50px;
  margin-top: 15px;
}

@primary-color: #FFA39E;