@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: local('Inter'), url(./assets/inter/Inter-SemiBold.ttf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Inter' !important;
  background: #f7f9fc !important;
}

.ant-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-picker-input > input[disabled]{
  color: #a9a9a9 !important;
}

.ant-layout-content{
  background: #ffffff !important;
  height: fit-content;
}

.ant-table-thead > tr > th {
  background: #ffffff !important;
}

.ant-btn{
  box-shadow: none !important;
}

.ant-btn[disabled],
.ant-checkbox-disabled .ant-checkbox-input,
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled,
.ant-picker.ant-picker-disabled,
.ant-picker-input > input[disabled]
{
  cursor: default !important;
}

.ant-btn.ant-dropdown-trigger{
  border-radius: 12px;
  height: 45px;
  box-shadow: none;
  content: none !important;
}

.ant-btn.ant-dropdown-trigger[disabled] {
  background: #efefef4d !important;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon{
  margin-left : 0px !important;
}

.ant-btn.ant-dropdown-trigger.ant-dropdown-open{
  content: none !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: none;
}

.ant-input-number-handler-wrap{
  display: none;
}

.ant-input-number{
 padding: 5px 0px !important;  
}

.h3.ant-typography{
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  color: #06122a;
}

.ant-input{
  border-radius: 12px !important;
  height: 40px;
}

.ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.ant-table-footer{
  background: white !important;
}

.ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 5px 0 !important;
}

.ant-menu-item-icon {
  font-size: 18px !important;
}

.ant-layout {
  background: #f7f9fc !important;
}
